import Login from "./Login";
import Layout from "../Components/Layout";
import AddNews from "./News/AddNews";
import ManageNews from "./News/ManageNews";
import EditNews from "./News/EditNews";
import ManageUser from "./User/ManageUser";
//import SendNotificaton from "./SendNotification";
import AddAdmin from "./Admin/AddAdmin";
import ManageAdmin from "./Admin/ManageAdmin";
import ManageFooter from "./FooterBanner/ManageFooter";
import AddFooterBanner from "./FooterBanner/AddFooterBanner";
import EditFooterBanner from "./FooterBanner/EditFooterBanner";
import Unauthorized from "./Unauthorized";
import RequireAuth from "../Components/RequireAuth";
import { Routes, Route } from "react-router-dom";
import PersistLogin from "../Components/PersistLogin";
import ManageFAQ from "./FAQ/ManageFAQ";
import AddFAQ from "./FAQ/AddFAQ";
import EditFAQ from "./FAQ/EditFAQ";
import SendNotificaton from "./SendNotification";
import Report from "./Report/Report";
import AppsalesReport from "./Report/AppsalesReport";
import DailyBalanceReport from "./Report/DailyBalanceReport";
import FeedbacksReport from "./Report/FeedbacksReport";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="login" element={<Login />} />
        <Route path="sendNotification" element={<SendNotificaton />} />
        {/* move to protected routes */}
        <Route path="unauthorized" element={<Unauthorized />} />
        <Route element={<PersistLogin />}>
          {/* protected routes */}
          <Route
            element={
              <RequireAuth allowedRoles={["admin", "manager", "staff"]} />
            }
          >
            <Route exact path="/" element={<Report />} />
            <Route path="manageNews" element={<ManageNews />} />
            <Route path="manageUser" element={<ManageUser />} />
            <Route path="addNews" element={<AddNews />} />

            <Route path="editNews/:newsID" element={<EditNews />} />
            <Route path="manageFooterBanner" element={<ManageFooter />} />
            <Route path="addFooterBanner" element={<AddFooterBanner />} />
            <Route
              path="editFooterBanner/:bannerID"
              element={<EditFooterBanner />}
            />
            <Route path="manageFAQ" element={<ManageFAQ />} />
            <Route path="addFAQ" element={<AddFAQ />} />
            <Route path="editFAQ/:FaqID" element={<EditFAQ />} />

            <Route path="sendNotification" element={<SendNotificaton />} />
            <Route path="AppsalesReport" element={<AppsalesReport />} />
            <Route path="DailyBalanceReport" element={<DailyBalanceReport />} />
            <Route path="FeedbacksReport" element={<FeedbacksReport />} />
          </Route>

          <Route element={<RequireAuth allowedRoles={["admin"]} />}>
            <Route path="addAdmin" element={<AddAdmin />} />
            <Route path="manageAdmin" element={<ManageAdmin />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
